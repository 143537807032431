.poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
}

html {
    font-family: "Poppins", sans-serif;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(48, 48, 48, 1) 45%, rgba(87, 87, 87, 1) 100%);
    position: fixed;
    top: 0;
    bottom: 0;
    letter-spacing: 1.5px;
    line-height: 1.5;
}

/* ROOT */
.root {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    position: fixed;
    top: 0;
    bottom: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.root::-webkit-scrollbar {
    display: none;
}

/* NAVBAR */
.navbar {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 20px;
    left: 0;
    right: 0;
    z-index: 1;
}

@media screen and (max-width: 600px) {
    .navbar {
        visibility: hidden;
    }
}

.navbar>div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5px;
    gap: 60px;
    background-color: white;
    border-radius: 40px;
}

.navbar>div>button {
    text-decoration: none;
    color: black;
    border: 0;
    background: transparent;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
}

.navbar>div>button:hover {
    color: black;
    background-color: orange;
    border-radius: 40px;
}

.navbar-selected {
    font-weight: 700;
    color: rgb(45, 18, 18) !important;
    background-color: orange !important;
    border-radius: 40px;
    border: 0;
}

.navbar-bottom {
    height: 1px;
    width: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(136, 136, 136, 1) 50%, rgba(0, 0, 0, 1) 100%);
}

/* ABOUT ME */
.about-me-root {
    width: 90%;
    margin-top: 120px;
    display: flex;
    flex-direction: row;
    gap: 40px;
}

@media screen and (max-width: 600px) {
    .about-me-root {
        flex-direction: column;
    }
}

.about-me-root>div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about-me-root>div:last-child {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center
}

.about-me-root>div>p {
    letter-spacing: 1.5px;
    line-height: 1.5;
}


.about-me-root>div>img {
    width: 500px;
    height: 500px;
    object-fit: contain;
    filter: drop-shadow(0px 0px 20px rgba(255, 166, 0, 0.5));
    margin-bottom: 50px;
}

.about-me-bottom-separator {
    height: 1px;
    width: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(136, 136, 136, 1) 50%, rgba(0, 0, 0, 0.2) 100%);
}

@media screen and (max-width: 600px) {
    .about-me-root {
        margin-top: 20px;
    }
    .about-me-root>div>img {
        width: 75% !important;
    }
}

/* SERVICES */
.service-card-container {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-block: 50px;
}

@media screen and (max-width: 600px) {
    .service-card-container {
        flex-direction: column;
        gap: 40px;
        margin-inline: 30px;
    }
}

.service-card {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.12);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4.1px);
    -webkit-backdrop-filter: blur(4.1px);
    gap: 10px;
}

.service-card-separator {
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.6);
}

.service-card>img {
    height: 300px;
    width: 400px;
    object-fit: cover;
    border-radius: 0 0 20px 20px;
}

@media screen and (max-width: 600px) {
    .service-card>img {
        width: 100%;
    }
}

.service-card>p {
    font-size: 20px;
    font-weight: 500;
    padding-inline: 20px;
    text-align: center;
}

/* COMPANIES */
.companies-root-container {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-block: 50px;
}

@media screen and (max-width: 600px) {
    .companies-root-container {
        flex-direction: column;
        align-items: center;
    }
}

.companies-root-container>a {
    display: flex;
    width: 300px;
    color: white;
    text-decoration: none;
    align-items: center;
}

.companies-root-container>a:hover {
    transform: scale(1.05);
}

.companies-root-container>a>img {
    height: 100px;
    width: 200px;
    object-fit: contain;
    padding-top: 10px;
}

/* CONTACT ME */
.contact-input-container {
    background-color: white;
    border-radius: 30px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-block: 50px;
    margin-inline: 20px;
    width: 70%;
}

.contact-input-container>input {
    flex: 1;
    border: 0;
    outline: none;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
}

@media screen and (max-width: 600px) {
    .contact-input-container>input {
        width: 100%;
    }
}

.contact-input-container>button {
    background-color: orange;
    border: 0;
    border-radius: 20px;
    color: rgb(45, 18, 18) !important;
    padding-inline: 20px;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 16px;
}

.contact-input-container>button:hover {
    transform: scale(1.1);
}

/* FOOTER */
.footer {
    background-color: orange;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 20px 20px 0 0;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-inline: 20px;
    gap: 30px;
}

.footer > a {
    color: rgb(45, 18, 18);
    text-decoration: none;
    font-size: 24px;
}

.footer > a:hover {
    transform: scale(1.2);
}